.form-container {
  max-width: 650px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--bg);
  border-radius: 12px;
  padding: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-heading {
  text-align: center;
  color: var(--text_primary);
}

.contact-desc {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--text_secondary);
}

.form-container input,
.form-container textarea,
.contact-send-btn {
  height: 3rem;
  margin: 0.5rem auto;
  width: 600px;
  border: 1px solid var(--text_secondary);
  border-radius: 12px;
  padding-left: 1rem;
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  color: var(--text_primary);
}

input {
  transform: scale(1);
}

input[type="text"]:focus,
textarea:focus {
  background-color: transparent;
  border: 1px solid var(--text_primary);
  border: #aaa5ff;
}

.form-container h2 {
  color: var(--text_primary);
  font-weight: 500;
  margin-left: 0.5rem;
}

.message-box {
  min-height: 200px;
  height: auto;
  padding-left: 1rem;
}

.contact-send-btn {
  font-weight: 500;
  font-size: 1.5rem;
  width: 300px;
  background-color: transparent;
  color: var(--text_primary);
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out !important;
  background: #aaa5ff;
  background: linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    225deg,
    hsla(271, 100%, 50%, 1) 0%,
    hsla(294, 100%, 50%, 1) 100%
  );
}

@media (max-width: 768px) {
  .form-container {
    max-width: 90%;
  }
  .form-container input,
  .form-container textarea,
  .contact-send-btn {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .form-container {
    max-width: 90%;
  }
  .form-container input {
    width: 95%;
  }
  .message-box {
    width: 95%;
  }
  .contact-send-btn {
    width: 95%;
  }
}
